import $ from 'jquery';
import * as UserApplication from '../models/userApplication';

const detailsForm = document.querySelector('#detailsForm');
detailsForm.addEventListener('submit', e => {
  e.preventDefault();
  const productForm = document.querySelector('#productForm');
  const marketForm = document.querySelector('#marketForm');
  const milesForm = document.querySelector('#milesForm');

  const formData = {
    //general details
    userName: detailsForm['userName'].value,
    startupName: detailsForm['startupName'].value,
    startupURL: detailsForm['startupURL'].value,
    userPhone: detailsForm['userPhone'].value,
    startupStage: detailsForm['startupStage'].value,
    targetMarket: detailsForm['targetMarket'].value,
    countryName: detailsForm['countryName'].value,
    //product
    problemDescription: productForm['problemDescription'].value,
    solutionDescription: productForm['solutionDescription'].value,
    startupDescription: productForm['startupDescription'].value,
    startupInnovation: productForm['startupInnovation'].value,
    startupMotivation: productForm['startupMotivation'].value,
    startupLaunch: productForm['startupLaunch'].value,
    //market
    marketGrowth: marketForm['marketGrowth'].value,
    marketShare: marketForm['marketShare'].value,
    marketCompetition: marketForm['marketCompetition'].value,
    businessModel: marketForm['businessModel'].value,
    marketSize: marketForm['marketSize'].value,
    //milestones
    startupFounders: milesForm['startupFounders'].value,
    teamSize: milesForm['teamSize'].value,
    startupRevenue: milesForm['startupRevenue'].value,
    startupUsers: milesForm['startupUsers'].value,
    startupIncorporation: milesForm['startupIncorporation'].value,
    startupFunding: milesForm['startupFunding'].value,
  };

  UserApplication.update(formData)
    .then(() => {
      // Handle success feedback
      console.log('[INFO] successfully updated the application form!');
      alert('Details successfully updated!');
    })
    .catch(error => {
      // Handle error feedback
      console.log(
        `[ERROR] failed in updating the application form:\n${error.message}`,
      );
    });
});

const productForm = document.querySelector('#productForm');
productForm.addEventListener('submit', e => {
  e.preventDefault();

  const detailsForm = document.querySelector('#detailsForm');
  const marketForm = document.querySelector('#marketForm');
  const milesForm = document.querySelector('#milesForm');

  const formData = {
    //general details
    userName: detailsForm['userName'].value,
    startupName: detailsForm['startupName'].value,
    startupURL: detailsForm['startupURL'].value,
    userPhone: detailsForm['userPhone'].value,
    startupStage: detailsForm['startupStage'].value,
    targetMarket: detailsForm['targetMarket'].value,
    countryName: detailsForm['countryName'].value,
    //product
    problemDescription: productForm['problemDescription'].value,
    solutionDescription: productForm['solutionDescription'].value,
    startupDescription: productForm['startupDescription'].value,
    startupInnovation: productForm['startupInnovation'].value,
    startupMotivation: productForm['startupMotivation'].value,
    startupLaunch: productForm['startupLaunch'].value,
    //market
    marketGrowth: marketForm['marketGrowth'].value,
    marketShare: marketForm['marketShare'].value,
    marketCompetition: marketForm['marketCompetition'].value,
    businessModel: marketForm['businessModel'].value,
    marketSize: marketForm['marketSize'].value,
    //milestones
    startupFounders: milesForm['startupFounders'].value,
    teamSize: milesForm['teamSize'].value,
    startupRevenue: milesForm['startupRevenue'].value,
    startupUsers: milesForm['startupUsers'].value,
    startupIncorporation: milesForm['startupIncorporation'].value,
    startupFunding: milesForm['startupFunding'].value,
  };

  UserApplication.update(formData)
    .then(() => {
      // Handle success feedback
      console.log('[INFO] successfully updated the application form!');
      alert('Details successfully updated!');
    })
    .catch(error => {
      // Handle error feedback
      console.log(
        `[ERROR] failed in updating the application form:\n${error.message}`,
      );
    });
});

const marketForm = document.querySelector('#marketForm');
marketForm.addEventListener('submit', e => {
  e.preventDefault();
  const productForm = document.querySelector('#productForm');
  const detailsForm = document.querySelector('#detailsForm');
  const milesForm = document.querySelector('#milesForm');

  const formData = {
    //general details
    userName: detailsForm['userName'].value,
    startupName: detailsForm['startupName'].value,
    startupURL: detailsForm['startupURL'].value,
    userPhone: detailsForm['userPhone'].value,
    startupStage: detailsForm['startupStage'].value,
    targetMarket: detailsForm['targetMarket'].value,
    countryName: detailsForm['countryName'].value,
    //product
    problemDescription: productForm['problemDescription'].value,
    solutionDescription: productForm['solutionDescription'].value,
    startupDescription: productForm['startupDescription'].value,
    startupInnovation: productForm['startupInnovation'].value,
    startupMotivation: productForm['startupMotivation'].value,
    startupLaunch: productForm['startupLaunch'].value,
    //market
    marketGrowth: marketForm['marketGrowth'].value,
    marketShare: marketForm['marketShare'].value,
    marketCompetition: marketForm['marketCompetition'].value,
    businessModel: marketForm['businessModel'].value,
    marketSize: marketForm['marketSize'].value,
    //milestones
    startupFounders: milesForm['startupFounders'].value,
    teamSize: milesForm['teamSize'].value,
    startupRevenue: milesForm['startupRevenue'].value,
    startupUsers: milesForm['startupUsers'].value,
    startupIncorporation: milesForm['startupIncorporation'].value,
    startupFunding: milesForm['startupFunding'].value,
  };

  UserApplication.update(formData)
    .then(() => {
      // Handle success feedback
      console.log('[INFO] successfully updated the application form!');
      alert('Details successfully updated!');
    })
    .catch(error => {
      // Handle error feedback
      console.log(
        `[ERROR] failed in updating the application form:\n${error.message}`,
      );
    });
});

const milesForm = document.querySelector('#milesForm');
milesForm.addEventListener('submit', e => {
  e.preventDefault();
  const productForm = document.querySelector('#productForm');
  const marketForm = document.querySelector('#marketForm');
  const detailsForm = document.querySelector('#detailsForm');

  const formData = {
    //general details
    userName: detailsForm['userName'].value,
    startupName: detailsForm['startupName'].value,
    startupURL: detailsForm['startupURL'].value,
    userPhone: detailsForm['userPhone'].value,
    startupStage: detailsForm['startupStage'].value,
    targetMarket: detailsForm['targetMarket'].value,
    countryName: detailsForm['countryName'].value,
    //product
    problemDescription: productForm['problemDescription'].value,
    solutionDescription: productForm['solutionDescription'].value,
    startupDescription: productForm['startupDescription'].value,
    startupInnovation: productForm['startupInnovation'].value,
    startupMotivation: productForm['startupMotivation'].value,
    startupLaunch: productForm['startupLaunch'].value,
    //market
    marketGrowth: marketForm['marketGrowth'].value,
    marketShare: marketForm['marketShare'].value,
    marketCompetition: marketForm['marketCompetition'].value,
    businessModel: marketForm['businessModel'].value,
    marketSize: marketForm['marketSize'].value,
    //milestones
    startupFounders: milesForm['startupFounders'].value,
    teamSize: milesForm['teamSize'].value,
    startupRevenue: milesForm['startupRevenue'].value,
    startupUsers: milesForm['startupUsers'].value,
    startupIncorporation: milesForm['startupIncorporation'].value,
    startupFunding: milesForm['startupFunding'].value,
  };
  if (milesForm['pitchDeck'].files.length > 0){
  UserApplication.uploadPitchdeck(milesForm['pitchDeck'].files[0])
    .then(url => {
      formData.pitchDeck = url;
      UserApplication.update(formData)
        .then(() => {
          // Handle success feedback
          console.log('[INFO] successfully updated the application form!');
          alert('Details successfully updated!');
        })
        .catch(error => {
          // Handle error feedback
          console.log(
            `[ERROR] failed in updating the application form:\n${
              error.message
            }`,
          );
        });
    })
    .catch(error => {
      // Handle error feedback
      console.log(`[ERROR] failed in uploading the file:\n${error.message}`);
    });
  }
});
